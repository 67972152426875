/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import home from '@/navigation/vertical/home';
import regions from "@/navigation/vertical/regions";
import categories from "@/navigation/vertical/categories";
import faqs from "@/navigation/vertical/faqs";
import locations from "@/navigation/vertical/locations";
import feedbacks from "@/navigation/vertical/feedbacks";
import messages from "@/navigation/vertical/messages";
import subcategories from "@/navigation/vertical/subcategories";
import faqCategories from "@/navigation/vertical/faqCategories";
import chatLink from "@/navigation/vertical/chatLink";
import SendPromo from "@/navigation/vertical/send-promo";
// import AdminsList from "@/navigation/vertical/admins-list";
import Disclaimer from "@/navigation/vertical/disclaimer";

// Array of sections
export default [
    ...categories,
    ...subcategories,
    ...faqCategories,
    ...faqs,
    ...regions,
    ...locations,
    ...feedbacks,
    ...messages,
    ...chatLink,
    ...SendPromo,
    // ...AdminsList,
    ...Disclaimer,
]
