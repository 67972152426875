<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="10"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div class="user d-flex align-items-center">
        <feather-icon class="user-icon" icon="UsersIcon" size="24"/>
        <p class="user-number">Количество пользователей: <span>{{ users }}</span></p>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block"/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import api from "@/services/api";

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      users: null
    }
  },

  async mounted() {
    await this.getUsersCount()
  },

  methods: {
    async getUsersCount() {
      await api.users.fetchUsers()
          .then(res => {
            this.users = res.data['subscribers_count']
          })
          .catch(error => {
            console.error(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  &-icon {
    height: 36px;
    width: 36px;
    padding: .25rem;
    background-color: #635bcb;
    border: 1px solid #635bcb;
    color: #d4d4d4;
    border-radius: 50%;
  }

  &-number {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: .5rem;
    color: #d4d4d4;
    margin-bottom: 0;

    & span {
      font-size: 1.4rem;
      font-weight: 600;
      color: #29c76f;
    }
  }
}
</style>
